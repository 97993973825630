@use "@neonjungle/birdseed/styles";

@import "mixins/mixin-loader";
@import "config";

// Global
@import "global/bases";

$accent-color: #ff5f1f;
$accent-color-light: lighten($accent-color, 18%);
$dark-brown: #1a0a03;
$middle-brown: lighten($dark-brown, 18%);

:root {
	--theme-text-color: white;
	--theme-accent-color: #{$accent-color};
	--theme-accent-color-light: #{$accent-color-light};
	--theme-page-background-color: #000000;
	--theme-ui-background-color: #{$dark-brown};
	--theme-ui-background-color-light: #{$middle-brown};
}

@import "components/gfx-block";

@import "layouts/fest23-home-fold";
@import "layouts/tito-widget";
