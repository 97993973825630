.l-fest23-home-fold {
	$c: &;
	position: relative;
	z-index: 2;
	margin-bottom: var(--p-rythm-lg);

	&--inner {
		position: relative;
		margin-top: 20vh;
	}
	&--background {
		height: 100vh;
		width: 100%;
		z-index: 1;
		position: relative;

		transition: opacity 800ms ease-in;
		transition-delay: 800ms;
		opacity: 0;

		.gfx-loaded & {
			opacity: 1;
		}

		canvas {
			margin: 0 calc(((100vw - var(--page-width)) / 2) * -1);
			display: block;
			height: 100%;
			width: 100vw;
			transform: translateZ(0);
		}
	}

	&--logo {
		transition: opacity 500ms ease-in;
		opacity: 0;
		.gfx-loaded & {
			opacity: 1;
		}

		position: absolute;
		left: 50%;
		top: 0;
		transform: translate(-50%, 6.3vh);
		width: 95%;
	}

	.theme-a11y & {
		#{$c}--logo img {
			filter: invert(1);
		}
		canvas {
			display: none !important;
		}
	}
}

@include from-sm {
	.l-fest23-home-fold {
		&--logo {
			transform: translate(-50%, calc(35% + 1vw));
		}
	}
}

@include from-md {
	.l-fest23-home-fold {
		&--inner {
			margin-top: 30vh;
		}
		&--background {
			height: 70vw;
		}
		&--logo {
			width: 80%;
		}
	}
}
